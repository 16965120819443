import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import { notChild } from "@/data/courseLists.json";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "Singapore University Course Statistics - SGUniStats (2025)",
      desc: "Find out which Singapore university course is for you; rank point calculator, IGP, GES, employment trends all in one website.",
      image:
        "https://images.unsplash.com/photo-1541339907198-e08756dedf3f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
      noIndex: false,
    },
  },
  {
    path: "/calculator",
    name: "calculator",
    component: () => import("../views/Calculator.vue"),
    meta: {
      title: "A level Rank Point Calculator & IGP - SGUniStats (2025)",
      desc: "Calculate your A level rank points and evaluate your chances of a successful university course application with IGP data.",
      image:
        "https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
      noIndex: false,
    },
  },
  {
    path: "/poly",
    name: "poly",
    component: () => import("../views/Poly.vue"),
    meta: {
      title: "Poly IGP Comparer - SGUniStats (2025)",
      desc: "Use your GPA to evaluate your chances of a successful university course application with IGP data.",
      image:
        "https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
      noIndex: false,
    },
  },
  {
    path: "/prospects",
    name: "prospects",
    component: () => import("../views/Prospects.vue"),
    meta: {
      title: "University Courses and Job Prospects - SGUniStats (2025)",
      desc: "Compare employment rates, incomes and job prospects between Singapore University courses using the Graduate Employment Survey.",
      image:
        "https://images.unsplash.com/photo-1569288063643-5d29ad64df09?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80",
      noIndex: false,
    },
  },
  {
    path: "/courses",
    name: "courses",
    component: () => import("../views/Courses.vue"),
    meta: {
      title: "University Course Information - SGUniStats (2025)",
      desc: "Find out which Singapore university course is for you; rank point calculator, IGP, GES, employment trends all in one website.",
      image:
        "https://images.unsplash.com/photo-1541339907198-e08756dedf3f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
      noIndex: false,
    },
    children: [
      {
        path: ":id",
        component: () => import("@/components/AppDashboard.vue"),
        meta: {
          title: `University Course Information - SGUniStats (2025)`,
          desc: "Find out which Singapore university course is for you; rank point calculator, IGP, GES, employment trends all in one website.",
          image:
            "https://images.unsplash.com/photo-1541339907198-e08756dedf3f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
          noIndex: true,
        },
        beforeEnter: (to, _, next) => {
          if (notChild.indexOf(+to.params.id) !== -1) {
            next();
          } else {
            next({ name: "courses" });
          }
        },
      },
    ],
  },
  {
    path: "/legal",
    name: "legal",
    component: () => import("../views/Legal.vue"),
    redirect: { name: "privacy" },
    meta: {
      title: `Privacy Policy - SGUniStats (2025)`,
      desc: "Find out which Singapore University course is for you. Privacy Policy & Terms for SGUniStats.",
      image:
        "https://images.unsplash.com/photo-1541339907198-e08756dedf3f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
      noIndex: true,
    },
    children: [
      {
        path: "privacy",
        name: "privacy",
        component: () => import("@/components/AppPrivacy.vue"),
        meta: {
          title: `Privacy Policy - SGUniStats (2025)`,
          desc: "Find out which Singapore University course is for you. Privacy policy for SGUniStats.",
          image:
            "https://images.unsplash.com/photo-1541339907198-e08756dedf3f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
          noIndex: true,
        },
      },
      {
        path: "terms",
        name: "terms",
        component: () => import("@/components/AppTerms.vue"),
        meta: {
          title: `Terms and Conditions - SGUniStats (2025)`,
          desc: "Find out which Singapore University course is for you. Terms and Conditions for SGUniStats.",
          image:
            "https://images.unsplash.com/photo-1541339907198-e08756dedf3f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
          noIndex: true,
        },
      },
    ],
  },
  {
    path: "/:catchAll(.*)*",
    redirect: { name: "home" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    // If no course ID, scroll to top.
    if (
      !(!!to.params.id && to.matched.some((route) => route.name === "courses"))
    ) {
      return { top: 0, behavior: "smooth" };
    }
  },
});
export default router;
