<template>
  <footer class="relative mt-6 text-blue-gray-600">
    <div
      class="
        absolute
        z-10
        w-full
        h-6
        rounded-t-full
        -top-6
        bg-blue-gray-100
        shadow-t
      "
    />
    <div class="pt-10 pb-10 bg-blue-gray-100">
      <div class="container grid grid-cols-4 px-3 mx-auto sm:px-0 gap-y-6">
        <!-- Logo and Social Links -->
        <div class="col-span-2">
          <router-link
            :to="{ name: 'home' }"
            class="
              flex
              items-center
              gap-1
              mb-2
              transition
              duration-300
              text-blue-gray-700
              sm:hover:text-sky-700
            "
          >
            <svg
              class="w-5 h-5 sm:w-6 sm:h-6"
              viewBox="0 0 499 493"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="261.638"
                y="247"
                width="48"
                height="255.276"
                rx="24"
                transform="rotate(30 261.638 247)"
                fill="currentColor"
              />
              <rect
                x="281.363"
                y="46"
                width="48"
                height="194.726"
                rx="24"
                transform="rotate(30 281.363 46)"
                fill="currentColor"
              />
              <rect
                x="303.387"
                y="283.56"
                width="48"
                height="134.68"
                rx="24"
                transform="rotate(143 303.387 283.56)"
                fill="currentColor"
              />
              <path
                d="M324.627 16.25L324.627 124.503L230.877 70.3766L324.627 16.25Z"
                fill="currentColor"
              />
              <path
                d="M339.615 132.427C331.095 122.274 332.42 107.136 342.574 98.6155C352.727 90.0954 367.866 91.4199 376.386 101.574L492.73 240.228C501.25 250.381 499.926 265.52 489.772 274.04C479.618 282.56 464.48 281.235 455.96 271.082L339.615 132.427Z"
                fill="currentColor"
              />
              <path
                d="M455.96 241.289C464.48 231.135 479.618 229.811 489.772 238.331C499.926 246.851 501.25 261.989 492.73 272.143L376.386 410.797C367.866 420.951 352.727 422.275 342.574 413.755C332.42 405.235 331.095 390.097 339.615 379.943L455.96 241.289Z"
                fill="currentColor"
              />
              <path
                d="M158.73 379.943C167.25 390.097 165.926 405.235 155.772 413.755C145.618 422.275 130.48 420.951 121.96 410.797L5.61551 272.143C-2.90453 261.989 -1.58012 246.851 8.57367 238.331C18.7275 229.811 33.8656 231.135 42.3856 241.289L158.73 379.943Z"
                fill="currentColor"
              />
              <path
                d="M42.3857 271.082C33.8656 281.235 18.7275 282.56 8.57368 274.04C-1.58011 265.52 -2.90453 250.381 5.61551 240.228L121.96 101.574C130.48 91.4199 145.618 90.0955 155.772 98.6155C165.926 107.136 167.25 122.274 158.73 132.427L42.3857 271.082Z"
                fill="currentColor"
              />
            </svg>

            <p class="font-bold sm:text-lg">SGUniStats</p>
          </router-link>
          <div class="flex gap-x-2 items-center mb-2">
            <a
              href="https://www.nathanielcalimag.com"
              target="_blank"
              rel="noopener noreferrer"
              class="duration-300 sm:hover:text-sky-600"
            >
              <svg
                class="w-6 h-6"
                viewBox="0 0 42.333332 42.333332"
                version="1.1"
                id="svg5"
                xmlns="http://www.w3.org/2000/svg"
                aria-labelledby="portfolioTitle"
              >
                <title id="portfolioTitle">Portfolio link</title>
                <g id="layer1">
                  <path
                    id="text3262"
                    style="
                      fontstyle: 'normal';
                      fontvariant: 'normal';
                      fontweight: '900';
                      fontstretch: 'normal';
                      fontsize: '50.8px';
                      lineheight: '1.25';
                      fontfamily: 'Apple SD Gothic Neo';
                      fontvariantligatures: 'normal';
                      fontvariantcaps: 'normal';
                      fontvariantnumeric: 'normal';
                      fontvarianteastasian: 'normal';
                    "
                    fill="currentColor"
                    d="M 0,0 V 34.137451 H 8.2132055 V
                  12.852445 H 8.4184181 L 20.737965,34.137451 h 8.715012 A
                  7.8975412,10.861648 0 0 1 24.912189,24.309627
                  7.8975412,10.861648 0 0 1 29.926062,14.202751 V 0 h -8.212684
                  v 19.100643 h -0.15404 L 10.420416,0 Z"
                  />
                  <path
                    id="text3926"
                    style="
                      fontstyle: 'normal';
                      fontvariant: 'normal';
                      fontweight: '900';
                      fontstretch: 'normal';
                      fontsize: '50.8px';
                      lineheight: '1.25';
                      fontfamily: 'Apple SD Gothic Neo';
                      fontvariantligatures: 'normal';
                      fontvariantcaps: 'normal';
                      fontvariantnumeric: 'normal';
                      fontvarianteastasian: 'normal';
                    "
                    fill="currentColor"
                    d="m 33.401623,7.0781208 c -9.496302,0
                  -17.04205,4.6733692 -17.04205,17.1193682 v 1.168404 c
                  0,11.582399 6.211199,16.96744 16.682798,16.96744 4.166341,0
                  6.999784,-0.682586 9.290962,-1.655712 v -6.820258 c
                  -1.688956,0.65377 -3.856699,1.262457 -7.083751,1.262457
                  -6.365089,0 -8.57246,-3.555902 -8.57246,-10.058301 v -1.523938
                  c 0,-6.5532 3.131539,-9.245947 8.675326,-9.245947 2.952448,0
                  4.94549,0.45034 6.980885,1.20096 V 8.5276448 c
                  -1.947041,-0.9492543 -4.656599,-1.449524 -8.93171,-1.449524 z"
                  />
                  <path
                    id="text3926-6"
                    style="
                      fontstyle: 'normal';
                      fontvariant: 'normal';
                      fontweight: '900';
                      fontstretch: 'normal';
                      fontsize: '25.4px';
                      lineheight: '1.25';
                      fontfamily: 'Apple SD Gothic Neo';
                      fontvariantligatures: 'normal';
                      fontvariantcaps: 'normal';
                      fontvariantnumeric: 'normal';
                      fontvarianteastasian: 'normal';
                    "
                    fill="currentColor"
                    d="m 38.072951,15.892053 c -4.748151,0
                  -8.521284,2.336684 -8.521284,8.559684 v 0.583944 c 0,5.791201
                  3.10586,8.48372 8.341659,8.48372 1.958775,0 3.330165,-0.300705
                  4.439486,-0.74104 v -3.41943 c -0.814439,0.295849
                  -1.851116,0.553971 -3.336143,0.553971 -3.182544,0
                  -4.285968,-1.777949 -4.285968,-5.02915 v -0.762227 c 0,-3.2766
                  1.56577,-4.622974 4.337663,-4.622974 1.376587,0
                  2.335962,0.197415 3.284448,0.528133 v -3.500561 c
                  -0.95764,-0.414348 -2.266146,-0.63407 -4.259861,-0.63407 z"
                  />
                </g>
              </svg>
            </a>
            <a
              href="https://github.com/nathanielcalimag"
              target="_blank"
              rel="noopener noreferrer"
              class="duration-300 sm:hover:text-sky-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                aria-hidden="true"
                role="img"
                class="w-7 h-7"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 1024 1024"
                aria-labelledby="githubTitle"
              >
                <title id="githubTitle">GitHub link</title>
                <path
                  d="M511.6 76.3C264.3 76.2 64 276.4 64 523.5C64 718.9 189.3 885 363.8 946c23.5 5.9 19.9-10.8 19.9-22.2v-77.5c-135.7 15.9-141.2-73.9-150.3-88.9C215 726 171.5 718 184.5 703c30.9-15.9 62.4 4 98.9 57.9c26.4 39.1 77.9 32.5 104 26c5.7-23.5 17.9-44.5 34.7-60.8c-140.6-25.2-199.2-111-199.2-213c0-49.5 16.3-95 48.3-131.7c-20.4-60.5 1.9-112.3 4.9-120c58.1-5.2 118.5 41.6 123.2 45.3c33-8.9 70.7-13.6 112.9-13.6c42.4 0 80.2 4.9 113.5 13.9c11.3-8.6 67.3-48.8 121.3-43.9c2.9 7.7 24.7 58.3 5.5 118c32.4 36.8 48.9 82.7 48.9 132.3c0 102.2-59 188.1-200 212.9a127.5 127.5 0 0 1 38.1 91v112.5c.8 9 0 17.9 15 17.9c177.1-59.7 304.6-227 304.6-424.1c0-247.2-200.4-447.3-447.5-447.3z"
                  fill="currentColor"
                />
              </svg>
            </a>
            <a
              href="https://forms.gle/KzkTRXQdAgNDafAU7"
              target="_blank"
              rel="noopener noreferrer"
              class="duration-300 sm:hover:text-sky-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-7 h-7"
                fill="currentColor"
                viewBox="0 0 256 256"
                aria-labelledby="emailTitle"
              >
                <title id="emailTitle">Email</title>
                <rect width="256" height="256" fill="none"></rect>
                <path
                  d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48ZM98.7,128,40,181.8V74.2Zm11.8,10.9,12.1,11a8,8,0,0,0,10.8,0l12.1-11L203.4,192H52.6ZM157.3,128,216,74.2V181.8Z"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/in/nathanielcalimag/"
              target="_blank"
              rel="noopener noreferrer"
              class="duration-300 sm:hover:text-sky-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-7 h-7"
                fill="currentColor"
                viewBox="0 0 256 256"
                aria-labelledby="linkedinTitle"
              >
                <title id="linkedinTitle">LinkedIn link</title>
                <rect width="256" height="256" fill="none"></rect>
                <path
                  d="M212,28H44A16.01817,16.01817,0,0,0,28,44V212a16.01833,16.01833,0,0,0,16,16H212a16.01833,16.01833,0,0,0,16-16V44A16.01817,16.01817,0,0,0,212,28ZM96,176.001a8,8,0,1,1-16,0v-64a8,8,0,0,1,16,0ZM88,92a12,12,0,1,1,12-12A12,12,0,0,1,88,92Zm96,84.001a8,8,0,1,1-16,0v-36a20,20,0,0,0-40,0v36a8,8,0,1,1-16,0v-64a7.99729,7.99729,0,0,1,15.79492-1.77857A35.98125,35.98125,0,0,1,184,140.001Z"
                ></path>
              </svg>
            </a>
          </div>
          <a
            href="https://forms.gle/KzkTRXQdAgNDafAU7"
            target="_blank"
            rel="noopener noreferrer"
            class="text-xs underline sm:hover:text-sky-800"
            >Leave some feedback!</a
          >
        </div>

        <!-- Tools -->
        <div>
          <p class="font-bold">Tools</p>
          <ul class="space-y-3">
            <li>
              <router-link
                :to="{ name: 'calculator' }"
                class="text-sm transition duration-300 sm:hover:text-sky-600"
                active-class="underline"
                >Calculator</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'prospects' }"
                class="text-sm transition duration-300 sm:hover:text-sky-600"
                active-class="underline"
                >Prospects</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'courses' }"
                class="text-sm transition duration-300 sm:hover:text-sky-600"
                active-class="underline"
                >Courses</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'poly' }"
                class="text-sm transition duration-300 sm:hover:text-sky-600"
                active-class="underline"
                >Poly</router-link
              >
            </li>
          </ul>
        </div>

        <!-- Legal -->
        <div>
          <p class="font-bold">Legal</p>
          <ul class="space-y-3">
            <li>
              <router-link
                :to="{ name: 'privacy' }"
                class="text-sm transition duration-300 sm:hover:text-sky-600"
                active-class="underline"
                >Privacy</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'terms' }"
                class="text-sm transition duration-300 sm:hover:text-sky-600"
                active-class="underline"
                >Terms</router-link
              >
            </li>
          </ul>
        </div>

        <!-- Copyright -->
        <p class="text-xs sm:text-sm col-span-full">
          &copy; 2025 SGUniStats. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>
